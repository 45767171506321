import React, { useState } from "react";

const ScholarsSection = () => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);

    // Set the loading state back to false after 3 seconds (3000 milliseconds)
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  return (
    <div className="py-4 px-5 scholarWrapper">
      <h5 className="scholarsHeader">A Push Forward Scholars</h5>
      <p className="mb-0 defaultP2">
        Are you a pre-law or law student ready to take your academic and
        professional journey to the next level?{" "}
        <span
          className="cursor-pointer text-blueColor"
          style={{ color: "#2a68ff", cursor: "pointer" }}
          data-toggle="modal"
          data-target="#notifyModal"
        >
          Enroll Now.
        </span>
      </p>

      <div className="mt-3 mb-5">
        <button className="learnMoreButtonField">Learn More &rarr;</button>
      </div>

      <div
        class="modal fade"
        id="notifyModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="notifyModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="notifyModalLabel">
                Enroll Now
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div>
                <div class="form-group">
                  <label for="firstName">First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="firstName"
                    placeholder=""
                  />
                </div>

                <div class="form-group">
                  <label for="lastName">Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="lastName"
                    placeholder=""
                  />
                </div>

                <div class="form-group">
                  <label for="email">Email Address</label>
                  <input
                    type="text"
                    class="form-control"
                    id="email"
                    placeholder=""
                  />
                </div>

                <div class="form-group">
                  <label for="phone">Phone Number</label>
                  <input
                    type="text"
                    class="form-control"
                    id="phone"
                    placeholder=""
                  />
                </div>

                <div className="mt-4 mb-4">
                  <button
                    className="modalButton btn-block"
                    disabled={loading}
                    onClick={handleClick}
                  >
                    {!loading ? (
                      "Submit"
                    ) : (
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarsSection;
