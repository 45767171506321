import React from "react";
import ComingSoonDetails from "./components/ComingSoonDetails";
import FooterrComponent from "./components/footer";
import FAQComponent from "./components/faq";
import ComingSoonNavbar from "./components/navSoon";
import ScholarsSection from "./components/ComingSoonDetails/scholars";

const ComingSoonPage = () => {
  return (
    <>
      {/* <ComingSoonNavbar /> */}
      <ComingSoonDetails />
      <ScholarsSection />
      <FooterrComponent />
    </>
  );
};

export default ComingSoonPage;
