import React from "react";
import "./index.css";
import WhiteLogo from "../../assets/white-logo.png";
import {
  FiLinkedin,
  FiInstagram,
  FiTwitter,
  FiYoutube,
  FiFacebook,
} from "react-icons/fi";

const FooterrComponent = () => {
  const handleFbClick = () => {
    // Use window.open to open the external URL in a new tab
    window.open(
      "https://www.facebook.com/profile.php?id=61556519092826&mibextid=rS40aB7S9Ucbxw6v",
      "_blank"
    );
  };

  const handleLinkedInClick = () => {
    // Use window.open to open the external URL in a new tab
    window.open("https://www.linkedin.com/company/apfwrd/", "_blank");
  };

  const handleIgClick = () => {
    // Use window.open to open the external URL in a new tab
    window.open("https://Instagram.com/apfwrd", "_blank");
  };

  const handleYoutubeClick = () => {
    // Use window.open to open the external URL in a new tab
    window.open(
      "https://youtube.com/@APushForward?si=fH1sMdVuwxzRKuz_",
      "_blank"
    );
  };
  return (
    <div className="footerBg py-5">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8  mb-4">
            <div className="d-flex align-items-center footerDiv">
              <div className="logoContainer">
                <img src={WhiteLogo} alt="greyLogo" />
              </div>
              <div>
                <p className="defaultPWhite mb-0 ml-3 noMleft">
                  {/* Together, let us embark on a transformative journey, where
                  mentorship becomes the cornerstone of success, and where we
                  empower the next generation of legal professionals to shape a
                  more just and equitable world. */}
                  Together, let's shape the future of mentorship.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 spaceIcons">
            <div className="flex justify-content-ends iconsContainer ">
              <span
                style={{
                  padding: "7px 10px",
                  color: "#0066f5",
                  backgroundColor: "#fff",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
                onClick={handleLinkedInClick}
              >
                <FiLinkedin size={16} />
              </span>
              <span
                style={{
                  padding: "7px 10px",
                  color: "#0066f5",
                  backgroundColor: "#fff",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
                className="ml-3"
                onClick={handleIgClick}
              >
                <FiInstagram size={16} />
              </span>

              {/* <span
                style={{
                  padding: "7px 10px",
                  color: "#0066f5",
                  backgroundColor: "#fff",
                  borderRadius: "50px",
                }}
                className="ml-3"
              >
                <FiTwitter size={16} />
              </span> */}

              <span
                style={{
                  padding: "7px 10px",
                  color: "#0066f5",
                  backgroundColor: "#fff",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
                className="ml-3"
                onClick={handleYoutubeClick}
              >
                <FiYoutube size={16} />
              </span>

              <span
                style={{
                  padding: "7px 10px",
                  color: "#0066f5",
                  backgroundColor: "#fff",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
                className="ml-3"
                onClick={handleFbClick}
              >
                <FiFacebook size={16} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr
        style={{ color: "#fff", backgroundColor: "#fff" }}
        className="mx-2 mt-2"
      />

      {/* <div className="footerLink">
          <ul>
            <li>Welcome</li>
            <li>Mission</li>
            <li>How it Works</li>
          </ul>
          <p className="defaultP">Abseyu 2023. All rights reserved</p>
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default FooterrComponent;
