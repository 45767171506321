import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RenderedScreens from "./RenderedScreens";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ComingSoonPage from "./ComingSoonPage";

function App() {
  return (
    <div className="">
      <Router>
        {/* <RenderedScreens /> */}
        <ComingSoonPage />
      </Router>
    </div>
  );
}

export default App;
